import { useState, useEffect } from 'react';
import { Maybe, YotpoBottomLine } from 'graphql-types';

type SingleProductBottomLineResponseFound = {
  response: {
    bottomline: {
      average_score: number;
      total_reviews: number;
    };
  };
  status: {
    code: 200;
    message: string;
  };
};

type SingleProductBottomLineResponseNotFound = {
  status: {
    code: 404;
    message: string;
    error_type: string;
    s: number;
    uuid: string;
  };
};

type SingleProductBottomLineResponse =
  | SingleProductBottomLineResponseFound
  | SingleProductBottomLineResponseNotFound;

export type YotpoProductBottomLine = Maybe<
  Pick<YotpoBottomLine, 'score' | 'totalReviews'>
>;

const getUpdatedBottomLine = async (
  id: string | undefined
): Promise<YotpoProductBottomLine> => {
  if (!id) {
    return undefined;
  }
  const data: SingleProductBottomLineResponse = await fetch(
    `https://api.yotpo.com/products/${process.env.GATSBY_YOTPO_API_KEY}/${id}/bottomline`
  ).then((r) => r.json());

  if ('response' in data) {
    return {
      score: data.response.bottomline.average_score,
      totalReviews: data.response.bottomline.total_reviews,
    };
  }

  return undefined;
};

type YotpoCacheById = Record<string, Promise<YotpoProductBottomLine>>;
const YotpoCache: YotpoCacheById = {};

export const useUpdatedBottomLine = (
  id: string | undefined,
  review: YotpoProductBottomLine
): YotpoProductBottomLine => {
  const [productReview, setProductReview] = useState(review || undefined);
  useEffect(() => {
    async function fetchUpdatedBottomLine() {
      if (!id || process.env.ENABLE_CUSTOMER_REVIEWS === 'false') {
        return null;
      }
      try {
        if (YotpoCache[id]) {
          const result = await YotpoCache[id];
          setProductReview(result);
        } else {
          const promise = getUpdatedBottomLine(id);
          YotpoCache[id] = promise;
          const result = await promise;
          setProductReview(result);
        }
      } catch (e) {
        console.error(e);
      }
      return null;
    }

    fetchUpdatedBottomLine();
  }, [id]);

  return productReview;
};

export function hasRatingData(
  yotpoReview: YotpoProductBottomLine,
  staticReviewData: Maybe<Partial<YotpoProductBottomLine>>
): boolean {
  if (
    typeof yotpoReview?.score === 'number' &&
    typeof yotpoReview?.totalReviews === 'number'
  ) {
    return true;
  }
  if (
    typeof staticReviewData?.score === 'number' &&
    typeof staticReviewData?.totalReviews === 'number'
  ) {
    return true;
  }
  return false;
}

export function getReviewsData(
  yotpoReview: YotpoProductBottomLine,
  staticReviewData: Maybe<Partial<YotpoProductBottomLine>>
): {
  displayRating: boolean;
  score: number;
  totalReviews: number;
} {
  return {
    displayRating: hasRatingData(yotpoReview, staticReviewData),
    score: yotpoReview?.score ?? staticReviewData?.score ?? 0,
    totalReviews:
      yotpoReview?.totalReviews ?? staticReviewData?.totalReviews ?? 0,
  };
}
