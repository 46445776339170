export function getStorageItemWithExpiration(key: string) {
  const itemString = window.localStorage.getItem(key);

  if (!itemString) {
    return null;
  }

  const item = JSON.parse(itemString);
  const now = new Date();
  if (now.getTime() > item.expirationDate) {
    // If the item is expired, delete the item from storage
    // and return null
    localStorage.removeItem(key);
    return null;
  }
  return item.value;
}

export function setStorageItemWithExpiration(
  key: string,
  value: any,
  expirationDays = 30
) {
  const expirationDate = new Date();
  expirationDate.setDate(expirationDate.getDate() + expirationDays);
  const item = {
    value: value,
    expirationDate: expirationDate.getTime(),
  };

  window.localStorage.setItem(key, JSON.stringify(item));
}
