import styled from 'styled-components';
import { flexbox, layout, space } from 'styled-utils';
import { Wrapper } from './ProductTile.styles';

export const ProductTileGridStyled = styled.div`
  display: flex;
  ${flexbox({ justifyContent: ['space-between', 'space-between', 'center'] })}
  max-width: 1280px;
  width: 100%;
  margin: 0 auto;
  flex-wrap: wrap;

  ${Wrapper} {
    ${layout({ width: ['calc(50% - 7px)', 296] })}
    ${space({ mx: [0, 0, '8px'], mb: [0, 0, 20] })}
  }
`;
