import React from 'react';
import styled from 'styled-components';
import { background } from 'styled-utils';

const Circle = styled.div`
  width: 1em;
  height: 1em;
  border-radius: 50%;
  ${background};
`;

export type ColorCircleProps = {
  color: string;
};

export const ColorCircle: React.FC<ColorCircleProps> = ({ color }) => {
  return <Circle background={color} />;
};
