import React from 'react';
import { BlockType } from 'types';

const getBlockId = (block: BlockType<unknown>) => {
  return `${(block._type || '').replace('sellPage', '')}-${(
    block._key || ''
  ).substr(0, 3)}`;
};

export const renderBlockWithId = (
  block: BlockType<unknown>,
  child: React.ReactNode
) => <div id={getBlockId(block)}>{child}</div>;
