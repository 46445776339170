import { UpdateCartItem } from 'api/cart/cart.api.model';
import { isIframe } from 'helpers/General';
import { useEffect, useState } from 'react';

const CART_LOAD_TIMEOUT = Number(process.env.GATSBY_CART_LOAD_TIMEOUT) || 10000;
const CART_LAZY_LOAD_DELAY =
  Number(process.env.GATSBY_CART_LAZY_LOAD_DELAY) || 1000;

/**
 * Returns an addToCart function that is wired to the cart iframe
 * returns
 */
export const postAddToCart = async (addCartItem: UpdateCartItem) => {
  const cartFrame = document.getElementById(
    'CartFrame'
  ) as HTMLIFrameElement | null;
  const inIframe = isIframe() || window.location.pathname === '/cart/';
  const cartWindow = inIframe ? window : cartFrame?.contentWindow;
  if (cartWindow) {
    cartWindow.postMessage(
      JSON.stringify({
        type: 'CartItem',
        data: addCartItem,
        inIframe,
      }),
      '*'
    );
  }
  return Promise.resolve();
};

let cartListener: (event: MessageEvent) => void;
const loadCart = (): Promise<void> =>
  new Promise((resolve, reject) => {
    if (window.hasCartMounted) {
      resolve();
      return;
    }

    const timeout = setTimeout(() => {
      if (!window.hasCartMounted) {
        reject('Cart failed to load in a timely manner. Please try again.');
      }
    }, CART_LOAD_TIMEOUT);

    if (!cartListener) {
      cartListener = ({ data }: MessageEvent) => {
        if (data === 'CartMounted') {
          window.removeEventListener('message', cartListener);
          clearTimeout(timeout);
          resolve();
        }
      };
      window.addEventListener('message', cartListener);
      window.postMessage('LoadCart', '*');
    }
  });

export const useCart = (options?: { lazyLoad?: boolean }) => {
  const [adding, setAdding] = useState(false);

  useEffect(() => {
    const cartFrame = document.getElementById(
      'CartFrame'
    ) as HTMLIFrameElement | null;
    // If the cart frame already exists, then assume the cart has been loaded.
    // This could happen if user navigates to a page first without the useCart hook,
    // opens the cart drawer, then navigates to one with. It should be loaded at this point.
    if (!cartFrame) {
      const cartListener = ({ data }: MessageEvent) => {
        if (data === 'CartMounted') {
          window.hasCartMounted = true;
          window.removeEventListener('message', cartListener);
        }
      };
      window.addEventListener('message', cartListener);

      if (options?.lazyLoad) {
        setTimeout(() => {
          window.postMessage('LoadCart', '*');
        }, CART_LAZY_LOAD_DELAY);
      }

      return () => {
        window.removeEventListener('message', cartListener);
      };
    } else {
      window.hasCartMounted = true;
    }

    return undefined;
  }, []);

  const addToCart = async (addCartItem: UpdateCartItem) => {
    setAdding(true);
    try {
      await loadCart();
      return postAddToCart(addCartItem);
    } catch (e) {
      return Promise.reject(e);
    } finally {
      setAdding(false);
    }
  };

  return {
    isAdding: adding,
    addToCart,
  };
};
