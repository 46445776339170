import React, { useContext } from 'react';
import { graphql } from 'gatsby';

import styled, { ThemeContext } from 'styled-components';
import { background, space, BackgroundProps, SpaceProps } from 'styled-utils';

interface Props {
  backgroundColor?: string;
  margin?: number;
  marginMobile?: number;
}

export const StyledSpacer = styled.div<BackgroundProps & SpaceProps>`
  ${background};
  ${space};
`;

export const Spacer = ({
  backgroundColor,
  margin,
  marginMobile,
}: Props): React.ReactElement => {
  const theme = useContext(ThemeContext);
  return (
    <StyledSpacer
      background={backgroundColor || theme.colors.basic100}
      pt={[`${marginMobile || 0}px`, `${margin || 0}px`]}
    />
  );
};

export const query = graphql`
  fragment SanitySellPageSpacer on SanitySellPageSpacer {
    _type
    __typename
    backgroundColor {
      hex
    }
    margin
    marginMobile
  }
`;
