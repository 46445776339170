import styled, { css } from 'styled-components';
import { space, flexbox, layout } from 'styled-utils';
import { Link } from 'gatsby';
import { isLadder } from 'helpers/Ladder';
import { isMobile } from '@bbnb/openfit-frontend-shared';

export const TileLink = styled(Link)`
  text-decoration: initial;
  color: ${({ theme }) => theme.colors.basic800};
  height: 100%;
  display: flex;
  flex-direction: column;
`;

export const Tile = styled.div`
  display: flex;
  position: relative;
  z-index: 1;
  height: 100%;
  flex-direction: column;
`;

export const TileBG = styled.div`
  background: ${({ theme }) => theme.colors.basic100};
  transition: all 100ms ease-out;
  position: absolute;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.25);
  opacity: 0;
  top: -12px;
  left: -12px;
  right: -12px;
  bottom: -12px;
  z-index: 0;
  ${layout({ display: ['none', 'none', 'block'] })};
`;

export const Wrapper = styled.div<{ useTileBGOverlay?: boolean }>`
  position: relative;
  z-index: 1;
  ${({ useTileBGOverlay = true }) =>
    useTileBGOverlay
      ? css`
          :hover {
            ${TileBG} {
              opacity: 1;
            }
          }
        `
      : css`
          height: calc(100% - 4px);
          width: calc(100% - 4px);
          :hover {
            box-shadow: 0 4px 10px rgba(0, 0, 0, 0.25);
          }
        `};
`;

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  ${isLadder() ? 'min-height: 160px;' : 'min-height: 130px;'}
  ${space({ px: [10, 0], pt: [12] })};
`;

export const Title = styled.h5`
  font-size: 14px;
  font-weight: 900;
  line-height: 20px;
  letter-spacing: 0;
  text-align: left;
  margin: 0;
  ${space({ mb: ['4px', '8px'] })};
`;

export const Rating = styled.div`
  display: flex;
  margin-bottom: 4px;
`;

export const Stars = styled.div`
  margin-top: 3px;
  margin-right: 3px;
  font-size: 11px;
`;

export const RatingText = styled.span`
  font-size: 12px;
  line-height: 18px;
  color: ${({ theme }) => theme.colors.basic600};
`;

export const Bottom = styled.div`
  display: flex;
  justify-content: space-between;
  height: 100%;
  flex: 1;
  ${flexbox({ flexDirection: ['column', 'row'] })};
`;

export const PriceText = styled(Link)`
  text-decoration: initial;
  color: ${({ theme }) => theme.colors.basic800};

  font-size: 12px;
  line-height: 18px;
`;

export const Colors = styled.div`
  display: flex;
  min-height: 18px;
  ${isMobile
    ? 'height:100px'
    : space({
        mt: ['8px', '-27px'],
        pr: ['24px', '24px', '10px'],
      })}
`;

export const Color = styled.div`
  font-size: 18px;
  min-width: 1em;
  cursor: pointer;
  ${space({ ml: [0, '8px'], mr: ['8px', 0] })};
`;

export const AddToCartContainer = styled.div`
  ${!isLadder()
    ? css`
        width: 95px;
        height: 32px;
        margin-top: 7px;
        margin-bottom: 14px;
      `
    : css`
        width: 150px;
        height: 42px;
        margin: 16px 0;
      `}
`;
