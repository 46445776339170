import {
  BundleItemsPricingMap,
  DiscountType,
  getBundlePricingInfo,
  bundleMapToList,
} from 'Context/BundleProduct/BundleProduct.helper';
import {
  Maybe,
  SanityOpenfitBundlePage,
  SanityProductVariant,
  ProductTileProductPageFragment,
  ProductTileBundlePageFragment,
  SanityProductSliderFragment,
  SanityLadderBundlePageOrLadderProductPage,
  LadderSliderProductWithUserTypeFragment,
  OpenfitCartCrossSellFragment,
  LadderCartCrossSellFragment,
  OpenfitProductCrossSellFragment,
  LadderProductCrossSellFragment,
  SanityLadderProductSliderFragment,
  LadderProductTileProductPageFragment,
  VariantOptionFlavorFragment,
} from 'graphql-types';
import { isValidForUserType } from 'helpers/Customer';
import { UserType } from 'types';

import { Color, ProductTileProps } from './ProductTile.model';
import {
  ProductSKU,
  sameSkuProductsVariants,
  simplifySku,
  skuInfo,
} from '@bbnb/openfit-frontend-shared';
import { MappedType } from 'utils/sku-cross-sell';
import {
  getVariantImage,
  ImageAwareObject,
} from 'Context/Product/Product.helper';

export enum ProductPageType {
  ProductPage = 'openfitProductPage',
  BundlePage = 'openfitBundlePage',
  LadderProductPage = 'ladderProductPage',
  LadderBundlePage = 'ladderBundlePage',
}

type ProductTileProductVariants = NonNullable<
  NonNullable<ProductTileProductPageFragment['product']>['variants']
>;

export function getProductPricingLine(
  variants: Maybe<ProductTileProductVariants>
): Maybe<string> {
  const prices = variants?.map((v) => v?.origPrice || v?.price) || [];
  const minPrice = Math.min.apply(null, prices.filter(Boolean) || []);
  if (prices.length > 1) return '';
  if (prices.length === 1) return `$${minPrice}`;
  return undefined;
}

export const getReviewCountText = (reviewCount: number): string =>
  reviewCount >= 1000000
    ? (reviewCount / 1000000).toFixed(2) + 'm'
    : reviewCount >= 1000
    ? (reviewCount / 1000).toFixed(2) + 'k'
    : `${reviewCount}`;

function getProductPageData(
  productPage: ProductTileProductPageFragment,
  defaultSku?: string
): ProductTileProps {
  const {
    defaultSku: productPageDefaultSku,
    product,
    slug,
    name,
  } = productPage;

  const productSku = defaultSku || productPageDefaultSku;
  const defaultVariant = product?.variants?.find((v) => v?.sku === productSku);

  const colors = new Map<string, Color>();
  const simpleProductSku = skuInfo(productSku) as ProductSKU;

  product?.variants?.forEach((variant) => {
    const variantSkuInfo = skuInfo(variant?.sku) as ProductSKU;
    if (!sameSkuProductsVariants(variantSkuInfo, simpleProductSku)) {
      return;
    }

    const varianOption = variant?.variantOptions?.find(
      (option) => option?.__typename === 'SanityVariantOptionFlavor'
    ) as Maybe<VariantOptionFlavorFragment>;

    if (varianOption?.colorSwatch?.hex) {
      const color = {
        sku: variant?.sku || '',
        name: varianOption.name || '',
        hex: varianOption.colorSwatch.hex || '',
      };

      colors.set(varianOption.colorSwatch.hex, color);
    }
  });

  const variantImage = getVariantImage(defaultVariant as ImageAwareObject);

  return {
    sku: [productSku || ''],
    image: variantImage,
    title: name || product?.name || '',
    priceText: getProductPricingLine(product?.variants),
    colors: Array.from(colors.values()),
    reviewId: product?.reviewId,
    review: product?.review,
    staticReviewData: product?.staticReviewData,
    link: `/products/${slug?.current}/${
      productSku ? `?sku=${productSku}` : ''
    }`,
    id: productSku,
  };
}

type PricingInfo = Pick<SanityProductVariant, 'price' | 'origPrice'>;
const productLowestPriceVariantFilter = (
  prev: PricingInfo,
  curr: PricingInfo
) => (((prev && prev.price) || 0) < ((curr && curr.price) || 0) ? prev : curr);

function getBundlePageData(
  bundlePage: ProductTileBundlePageFragment,
  bundleQueryString?: string
): ProductTileProps {
  const { name, bundle, slug } = bundlePage as SanityOpenfitBundlePage;
  const {
    products,
    images,
    discountAmount,
    discountType,
    review,
    reviewId,
    staticReviewData,
  } = bundle || {};

  const image = images?.[0]?.image;

  const bundleItemMap =
    products?.reduce((accum, bundleProductData, index) => {
      if (bundleProductData?.product?.variants) {
        const groupName =
          bundleProductData.groupName ||
          bundleProductData.product.name ||
          index.toString();
        const existingVariant = accum[groupName];
        if (!existingVariant) {
          accum[groupName] = bundleProductData.product.variants.reduce(
            productLowestPriceVariantFilter
          ) as SanityProductVariant;
        } else {
          accum[groupName] = [
            existingVariant,
            ...bundleProductData.product.variants,
          ].reduce(productLowestPriceVariantFilter) as SanityProductVariant;
        }
      }
      return accum;
    }, {} as BundleItemsPricingMap) || {};

  const items = bundleMapToList(bundleItemMap);

  const { price, origPrice } = getBundlePricingInfo(
    items,
    discountType as DiscountType,
    discountAmount
  );

  const saveAmount = Math.round(origPrice - price);
  let priceText = `$${price.toFixed(2)}`;
  if (saveAmount > 0) {
    priceText += ` (save $${saveAmount})`;
  }

  let skus = items.map((item) => item.sku || '');

  let id: string | undefined;
  if (bundleQueryString && bundle?.bundleId) {
    const skusFromBundleQueryString = Array.from(
      new URLSearchParams(bundleQueryString).values()
    );

    if (skusFromBundleQueryString.length === skus.length) {
      skus = skusFromBundleQueryString;
      id = `${bundle.bundleId}_${skus.join('_')}`;
    }
  }

  return {
    sku: skus,
    image,
    title: name || '',
    priceText,
    reviewId,
    review,
    staticReviewData,
    link: `/products/${slug?.current}/${
      bundleQueryString ? `?${new URLSearchParams(bundleQueryString)}` : ''
    }`,
    id,
  };
}

type OpenfitProductSliderProducts =
  | NonNullable<SanityProductSliderFragment['products']>
  | NonNullable<SanityLadderProductSliderFragment['products']>;

export function getProducts(
  products:
    | OpenfitProductSliderProducts
    | Maybe<Array<Maybe<OpenfitProductCrossSellFragment>>>
    | Maybe<Array<Maybe<LadderProductCrossSellFragment>>>
    | Maybe<Array<Maybe<SanityLadderBundlePageOrLadderProductPage>>>,
  isEntitled: boolean
): ProductTileProps[] {
  if (!products) return [];

  const tiles: ProductTileProps[] = [];

  for (let product of products) {
    const userType = ((product as OpenfitProductCrossSellFragment)?.userType ||
      '') as UserType;

    if (!isValidForUserType(isEntitled, userType)) {
      continue;
    }

    const skuOrQueryString = (product as any)['skuOrQueryString'];

    if (
      product?._type === 'ladderProductWithUserType' ||
      product?._type === 'ladderProductCrossSell'
    ) {
      product = (product as LadderSliderProductWithUserTypeFragment).product;
    }

    const pageType =
      product?._type === 'productWithUserType' ||
      product?._type === 'productCrossSell'
        ? (product as LadderSliderProductWithUserTypeFragment)?.product?._type
        : (product as SanityLadderBundlePageOrLadderProductPage)._type;

    let tile: ProductTileProps | null = null;
    switch (pageType as ProductPageType) {
      case 'openfitProductPage':
        tile = getProductPageData(
          (product as LadderSliderProductWithUserTypeFragment)
            ?.product as ProductTileProductPageFragment,
          skuOrQueryString
        );
        break;
      case 'openfitBundlePage':
        tile = getBundlePageData(
          (product as LadderSliderProductWithUserTypeFragment)
            ?.product as ProductTileBundlePageFragment,
          skuOrQueryString
        );
        break;
      case 'ladderProductPage':
        tile = getProductPageData(
          product as ProductTileProductPageFragment,
          skuOrQueryString
        );
        break;
      case 'ladderBundlePage':
        tile = getBundlePageData(
          product as ProductTileBundlePageFragment,
          skuOrQueryString
        );
        break;
    }
    if (tile) tiles.push(tile);
  }

  return tiles;
}

type OpenfitCrossSellProductsFragment = NonNullable<
  NonNullable<OpenfitCartCrossSellFragment['products']>[0]
>;
type LadderCrossSellProductsFragment = NonNullable<
  NonNullable<LadderCartCrossSellFragment['products']>[0]
>;

export type OpenfitOrLadderProducts =
  | OpenfitCrossSellProductsFragment
  | LadderCrossSellProductsFragment;

type ProductOnlyPages =
  | ProductTileProductPageFragment
  | LadderProductTileProductPageFragment;

export const simplifyCrossSell = (
  productOrBundle: OpenfitOrLadderProducts
): MappedType => {
  const isProduct = !!(productOrBundle.product as ProductOnlyPages)?.product;

  if (isProduct) {
    const product = productOrBundle.product as ProductOnlyPages;

    return {
      id: product.slug?.current || '',
      items: [[simplifySku(product.defaultSku) || '']],
    };
  }

  const bundle = productOrBundle.product as ProductTileBundlePageFragment;

  // Bundle
  return {
    id: bundle.slug?.current || '',
    items:
      bundle.bundle?.products?.map((product): string[] => {
        return (
          product?.skus?.map((sku: string): string => simplifySku(sku) || '') ||
          []
        );
      }) || [],
  };
};
