import React, { MouseEvent } from 'react';
import {
  getProducts,
  simplifyCrossSell,
} from 'components/Product/Tile/ProductTile.helper';
import {
  LadderCartCrossSellFragment,
  LadderProductCrossSellFragment,
  OpenfitCartCrossSellFragment,
  OpenfitProductCrossSellFragment,
} from 'graphql-types';
import { Cart } from 'api/cart/cart.api.model';
import { simplifySku, useUserData } from '@bbnb/openfit-frontend-shared';
import { logEvent } from 'helpers/Amplitude';
import { getAllCartProductSKUs } from 'helpers/cart/Cart.helper';
import { filterOutUsedCrossSells } from 'utils/sku-cross-sell';
import { ProductTileProps } from 'components/Product/Tile/ProductTile.model';
import { ProductTile } from 'components/Product/Tile/ProductTile';
import { ProductTileGrid } from 'components/Product/Tile/ProductTileGrid';
import { LoadingPlaceHolder } from 'components/Common/LoadingPlaceholder/LoadingPlaceholder';
import { PlaceholderContainer } from 'components/Common/LoadingPlaceholder/LoadingPlaceholder.styles';
import { CartCrossSellStyled, Title } from './CartCrossSell.styles';
import { postAddToCart } from 'hooks/use-cart';

export type CartCrossSellProductType =
  | OpenfitProductCrossSellFragment
  | LadderProductCrossSellFragment;

export type CartCrossSellType =
  | OpenfitCartCrossSellFragment
  | LadderCartCrossSellFragment;

export const handleCrossSellClick = (
  location = 'Product Page',
  isCartAdd = false
) => (productTileProps: ProductTileProps) => {
  return {
    ...productTileProps,
    onClick: async () => {
      await logEvent('Cross Sell Clicked', {
        product_name: productTileProps.title,
        sku: productTileProps.sku,
        location,
      });

      window.parent.location.assign(productTileProps?.link || '');
    },
    addToCartClick: isCartAdd
      ? async (e: MouseEvent) => {
          e.preventDefault();
          e.stopPropagation();
          await Promise.all([
            logEvent(`${location}: Add To Cart`, {
              name: productTileProps.title,
              sku: productTileProps.sku,
              price: productTileProps.priceText,
            }),
            productTileProps.id &&
              postAddToCart({
                id: productTileProps.id,
                quantity: 1,
              }),
          ]);
        }
      : undefined,
  };
};

export const CartCrossSell: React.FC<{
  cart?: Cart;
  crossSell?: CartCrossSellType;
}> = ({ cart, crossSell }) => {
  const { role, loading } = useUserData();

  if (loading) {
    return (
      <PlaceholderContainer>
        <LoadingPlaceHolder width={'100%'} height={'321px'} />
      </PlaceholderContainer>
    );
  }

  if (!cart || !crossSell) {
    return null;
  }

  const crossSellProducts: CartCrossSellProductType[] =
    (crossSell.products as CartCrossSellProductType[]) || [];

  const simplifiedCartItems = getAllCartProductSKUs(cart).map(
    (product) => simplifySku(product) || ''
  );
  const simplifiedCrossSellItems = crossSellProducts.map(simplifyCrossSell);

  const pickedElementsFromCrossSell = filterOutUsedCrossSells(
    simplifiedCartItems,
    simplifiedCrossSellItems
  );

  const isEntitled = role === 'subscriber';

  const productsFromCrossSells = getProducts(
    crossSellProducts.filter((p) =>
      pickedElementsFromCrossSell.includes(p?.product?.slug?.current || '')
    ),
    isEntitled
  );

  const productsToDisplay = productsFromCrossSells
    .slice(0, crossSell.displayLimit)
    .map((product) => {
      product.useTileBGOverlay = false;
      return handleCrossSellClick('Cart', crossSell.canAddToCart)(product);
    });

  return productsToDisplay.length ? (
    <CartCrossSellStyled>
      {crossSell.title ? <Title>{crossSell.title}</Title> : null}
      <ProductTileGrid>
        {productsToDisplay.map(({ colors, ...product }, idx) => (
          <ProductTile key={idx} {...product} />
        ))}
      </ProductTileGrid>
    </CartCrossSellStyled>
  ) : null;
};
