import React from 'react';
import { navigate } from 'gatsby';

import { StarRating } from 'components/Product/StarRating/StarRating';
import { ColorCircle } from 'components/Common/ColorCircle';
import {
  Wrapper,
  Tile,
  TileBG,
  Content,
  Title,
  Rating,
  RatingText,
  Bottom,
  PriceText,
  Colors,
  Color,
  Stars,
  TileLink,
  AddToCartContainer,
} from './ProductTile.styles';
import { ProductTileProps } from './ProductTile.model';
import { getReviewsData, useUpdatedBottomLine } from 'helpers/Yotpo';
import { getSimplifiedImageData } from 'helpers/SanityMapper';
import { getReviewCountText } from './ProductTile.helper';
import { ProductImage } from 'components/Product/ProductImage';
import { Tooltip, getStandardParams } from '@bbnb/openfit-frontend-shared';
import { AddToCartButton } from 'components/AddToCartButton/AddToCartButton';

export const ProductTile: React.FC<ProductTileProps> = (props) => {
  const {
    image,
    title,
    reviewId,
    review,
    staticReviewData,
    priceText,
    colors,
    link,
    onClick,
    addToCartClick,
    useTileBGOverlay,
  } = props;
  const productReview = useUpdatedBottomLine(reviewId, review);

  const onTileClick = (e: React.MouseEvent<HTMLAnchorElement>) => {
    e.preventDefault();
    if (onClick) return onClick(props);
    link && navigate(link);
    return null;
  };

  const { displayRating, score, totalReviews } = getReviewsData(
    productReview,
    staticReviewData
  );

  const imageData = getSimplifiedImageData(image);

  return (
    <Wrapper useTileBGOverlay={useTileBGOverlay}>
      <Tile>
        <TileLink to={link || ''} onClick={onTileClick}>
          {imageData && (
            <ProductImage
              image={{
                image: imageData,
                alt: title,
                imgStyle: { objectFit: 'contain' },
              }}
            />
          )}
          <Content>
            <Title>{title}</Title>
            {displayRating && process.env.ENABLE_CUSTOMER_REVIEWS === 'true' && (
              <Rating>
                <Stars>
                  <StarRating rating={score} />
                </Stars>
                <RatingText>({getReviewCountText(totalReviews)})</RatingText>
              </Rating>
            )}
            <Bottom>
              <PriceText
                to={link || ''}
                onClick={onTileClick}
                dangerouslySetInnerHTML={{ __html: priceText || '' }}
              />
              {colors && (
                <Colors>
                  {colors.map((color, index) => (
                    <Color
                      key={`${index}-prime`}
                      onClick={(e) => {
                        e.preventDefault();
                        e.stopPropagation();
                        const search = link?.split('?')[1];
                        const params = getStandardParams(search || '');

                        return navigate(
                          link?.replace(params.sku, color.sku) || ''
                        );
                      }}
                    >
                      <Tooltip text={color.name}>
                        <ColorCircle color={color.hex} />
                      </Tooltip>
                    </Color>
                  ))}
                </Colors>
              )}
              {addToCartClick && (
                <AddToCartContainer>
                  <AddToCartButton onClick={addToCartClick} />
                </AddToCartContainer>
              )}
            </Bottom>
          </Content>
        </TileLink>
      </Tile>
      <TileBG />
    </Wrapper>
  );
};
