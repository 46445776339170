export type MappedType = { id: string; items: string[][] };

export const filterOutUsedCrossSells = (
  usedItems: string[],
  crossSells: MappedType[]
) => {
  return crossSells
    .filter((crossSell) => {
      const isBundle = crossSell.items.length > 1;
      if (isBundle) {
        const containsSubscription = usedItems.includes('DIGOF');
        if (!containsSubscription) {
          return true;
        }

        return !crossSell.items.some((products) => {
          return !products.includes('DIGOF');
        });
      }

      const leftItems = crossSell.items.filter((productItems) => {
        const leftProductVariants = productItems.filter((item) => {
          return !usedItems.includes(item);
        });

        return !!leftProductVariants.length;
      });

      return leftItems.length === crossSell.items.length;
    })
    .map((crossSell) => crossSell.id);
};
