import { useEffect, useState } from 'react';
import { logout } from 'helpers/Customer';
import { logError } from 'helpers/Log';
import { getCart, updateCart } from 'api/cart/cart.api';
import { getStorageItemWithExpiration } from 'helpers/storage';
import { UpdateCartItem, Cart, CartItem } from 'api/cart/cart.api.model';
import { CART_STORAGE_KEY } from 'Constants/general';
import { useUserData } from '@bbnb/openfit-frontend-shared';

/**
 * Merges cart data from local storage with cart data from API
 * and performs update
 * @param localCart
 * @param apiCart
 * @returns {Cart} updatedCart
 */
async function mergeCart(localCart: Cart, apiCart: Cart) {
  let updatedCart: Cart | null = null;

  let cartItems: UpdateCartItem[] = [];
  if (!apiCart) {
    cartItems = localCart.items.map(({ id, quantity }) => ({
      id,
      quantity,
    }));
  } else {
    // Create map of the id and quantity of whats in local storage
    const localCartMap = localCart.items.reduce((prev, current) => {
      prev[current.id] = current.quantity;
      return prev;
    }, {} as { [id: string]: number });

    // Iterate the cart response and merge the quantities of local and api carts
    cartItems = apiCart.items.map(({ id, quantity }) => {
      const localQuantity = localCartMap[id];

      if (Number.isInteger(localQuantity)) {
        delete localCartMap[id];
      }

      return {
        id,
        quantity: quantity + (localQuantity || 0),
      };
    });

    const localCartMapKeys = Object.keys(localCartMap);
    localCartMapKeys.forEach((key) => {
      cartItems.push({ id: key, quantity: localCartMap[key] });
    });
  }

  // Use coupon from api and fallback to stored coupon
  const coupon = apiCart?.coupon || localCart.coupon;

  // Update the cart
  updatedCart = (await updateCart({ coupon, items: cartItems })) as Cart;

  // Remove the stored cart
  window.localStorage.removeItem(CART_STORAGE_KEY);

  return updatedCart;
}

/**
 * Returns cartState seeded with the users' previously stored cart data if any
 */
export const useGetCart = () => {
  const { role, loading } = useUserData();
  const [cart, setCart] = useState<Cart | undefined>();
  const [cartLoaded, setCartLoaded] = useState(false);

  useEffect(() => {
    if (loading) {
      return;
    }

    let localStorageCart: Cart | undefined;
    try {
      const cartString = getStorageItemWithExpiration(CART_STORAGE_KEY);
      localStorageCart = cartString && JSON.parse(cartString);
    } catch (e) {
      // Might be nice for debugging but don't want to log these in production
      if (process.env.GATSBY_ENV !== 'prod') {
        logError(e);
      }
    }

    if (role === 'visitor') {
      if (!localStorageCart) {
        setCartLoaded(true);
        return;
      }

      // fetch guest cart with
      updateCart({
        items: localStorageCart.items.map(({ id, quantity }) => ({
          id,
          quantity,
        })),
      })
        .then((cart) => {
          setCart(cart as Cart);
          setCartLoaded(true);
        })
        .catch(logError);
    } else {
      getCart()
        .then(async (cartResult) => {
          let cart = cartResult as Cart;
          // If we had items in logged out cart merge them with the cart response
          if (localStorageCart && localStorageCart.items?.length) {
            cart = await mergeCart(localStorageCart, cart);
          }
          setCart(cart);
          setCartLoaded(true);
        })
        .catch((e) => {
          logError(e);
          logout();
        });
    }
  }, [loading]);
  return {
    cart,
    setCart,
    cartLoaded,
  };
};

export const getAllCartProductSKUs = (cart: Cart) => {
  const idList: Record<string, boolean> = {};

  const productReducer = (productIds: string[], cartItem: CartItem) => {
    const itemsSkus: string[] = [
      ...productIds,
      ...(cartItem.subItems?.reduce(productReducer, []) || []),
    ];

    const sku = cartItem.variant?.sku;
    if (!sku) {
      // skip undefined
      return itemsSkus;
    }

    if (idList[sku]) {
      // already at list
      return itemsSkus;
    }

    idList[sku] = true;

    return [...itemsSkus, sku];
  };

  return cart.items.reduce(productReducer, []);
};
