import React, { MouseEventHandler } from 'react';
import { StyledAddToCartButton } from './AddToCartButton.styles';

export const AddToCartButton: React.FC<{
  onClick: MouseEventHandler;
}> = ({ onClick }) => (
  <StyledAddToCartButton onClick={onClick} variant={'primaryBorder'}>
    Add to Cart
  </StyledAddToCartButton>
);
