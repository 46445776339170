import { Button } from 'components/Common/Button';
import { StyledButton } from 'components/Common/Button/styles';
import { isLadder } from 'helpers/Ladder';
import styled from 'styled-components';
import { minWidth, typography, variant } from 'styled-system';
import { LadderThemeProp } from 'theme';

export const StyledAddToCartButton = styled(Button as typeof StyledButton)`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: inherit;
  ${({ theme }) => typography(theme.typography.xSmall)};
  ${minWidth({ minWidth: ['auto', 'auto'] })}

  &&& {
    ${({ theme }: LadderThemeProp) =>
      isLadder()
        ? variant({
            variants: {
              primaryBorder: {
                border: `1px solid ${theme.colors.basic400}`,
                backgroundColor: 'transparent',
                color: theme.colors.blue700,
                borderRadius: '35px',
                '&:hover': {
                  backgroundColor: 'transparent',
                  color: theme.colors.blue500,
                },
              },
            },
          })
        : variant({
            variants: {
              primaryBorder: {
                '&:hover': {
                  border: `1px solid ${theme.colors.orangeHover}`,
                  color: theme.colors.orangeHover,
                  backgroundColor: 'transparent',
                  fontWeight: theme.fontWeights.bold,
                },
              },
            },
          })}
  }
`;
