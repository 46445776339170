import styled from 'styled-components';
import { Title as SliderTitle } from 'components/Product/Slider/ProductSlider.styles';
import { typography } from '@bbnb/openfit-frontend-shared';
import { ProductTileGridStyled } from '../../Product/Tile/ProductTileGrid.styles';

export const CartCrossSellStyled = styled.div`
  padding: 0 20px;

  ${ProductTileGridStyled} {
    > * {
      margin: 5px 0;
    }
  }
`;

export const Title = styled(SliderTitle)`
  ${typography('heading4')}
`;
