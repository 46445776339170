import React from 'react';
import {
  Container,
  StarContainer,
  StarRated,
  StarUnrated,
} from './StarRating.styles';

const stars = Array.from({ length: 5 });

export type StarRatingProps = {
  rating: number;
  starColorMobile?: string;
  starColorDesktop?: string;
};

export const StarRating: React.FC<StarRatingProps> = ({
  rating,
  starColorMobile,
  starColorDesktop,
}) => (
  <Container>
    {stars.map((arg, index) => {
      let individualStarRating = 1;

      if (index + 1 > rating) {
        individualStarRating = Math.ceil(rating) <= index ? 0 : rating - index;
      }

      return (
        <StarContainer key={index}>
          <StarUnrated>
            <StarRated
              rating={individualStarRating}
              backgroundDesktop={starColorDesktop}
              backgroundMobile={starColorMobile}
            />
          </StarUnrated>
        </StarContainer>
      );
    })}
  </Container>
);
