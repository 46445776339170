import styled from 'styled-components';
import starRating from 'svg/star-rating.svg';

import {
  screenAwareBackground,
  ScreenAwareBackgroundProps,
} from 'components/Common/Placement/Placement';

export const Container = styled.div`
  display: flex;
  justify-content: inherit;
`;

export const StarUnrated = styled.div`
  background-color: ${({ theme }) => theme.ratings.unratedColor};
  /* stylelint-disable-next-line plugin/no-unsupported-browser-features */
  mask-image: url(${starRating.url});
  background-size: 1em;
  height: 1em;
  width: 1em;
  display: inline-block;
`;

export const StarRated = styled.div<
  { rating: number } & ScreenAwareBackgroundProps
>`
  ${({ backgroundMobile, backgroundDesktop, theme }) =>
    screenAwareBackground(
      backgroundMobile || theme.ratings.ratedColor,
      backgroundDesktop || theme.ratings.ratedColor
    )};
  /* stylelint-disable-next-line plugin/no-unsupported-browser-features */
  mask-image: url(${starRating.url}) no-repeat repeat left;
  background-size: 1em;
  height: 1em;
  width: ${(props) => props.rating}em;
`;

export const StarContainer = styled.span`
  margin-right: 0.4em;
  line-height: 1em;
`;
