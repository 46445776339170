import { SanityOpenfitProduct } from './../../../graphql-types';
import { useMemo } from 'react';
import {
  OpenfitProduct,
  SupplementProductVariant,
} from '../Product/Product.context.model';
import {
  BundleGroupMap,
  BundleItemDisplayNameMap,
  BundleItemMap,
  BundleProducts,
  BundleVariant,
  SubscriptionIntervalCollection,
} from './BundleProduct.context';
import { useLocation } from '@reach/router';
import {
  getVariantImage,
  ImageAwareObject,
} from 'Context/Product/Product.helper';
import { SanityImage, SanityProductVariant } from 'graphql-types';

export type BundleProductItem = {
  productId?: string;
  name: string;
  image?: SanityImage;
  sku?: string;
  stripeProductId?: string;
  description?: string;
  nsfCertification: boolean;
  nsfCertificationLink: string;
  isFreeWithOrigPrice: boolean;
  price?: number;
  origPrice?: number;
  ingredientsImage?: SanityImage;
};

export function getProductFromBundleProducts(
  bundleProducts: BundleProducts = []
): OpenfitProduct | null {
  if (!bundleProducts.length) {
    return null;
  }
  // Build Variant Map from Group
  const mappedProduct = {
    productType: bundleProducts[0]?.product?.productType,
    variants: [] as SupplementProductVariant[],
  };

  bundleProducts.forEach((product) => {
    if (product && product.product) {
      product?.product?.variants?.forEach((variant) => {
        mappedProduct.variants.push({
          ...(variant as SupplementProductVariant),
          text: product.product?.name || '',
        });
      });
    }
  });

  return mappedProduct as OpenfitProduct;
}

export type BundleItemsPricingMap = {
  [productGroupName: string]: Pick<
    SanityProductVariant,
    'price' | 'origPrice' | 'sku'
  >;
};

export type DiscountType = 'percent';

export type BundleItemPriceInfo = { price?: number; origPrice?: number };
export function getBundlePricingInfo(
  bundleItems: BundleItemPriceInfo[],
  discountType: DiscountType = 'percent',
  discountAmount = 0
): { price: number; origPrice: number } {
  let price = 0;
  let origPrice = 0;

  Array.isArray(bundleItems) &&
    bundleItems.forEach((item) => {
      const productPrice = item.price || 0;

      const productOrigPrice = item.origPrice || 0;
      if (discountType === 'percent' && discountAmount) {
        const productDiscount =
          Math.round((productPrice * 100 * discountAmount) / 100) / 100;
        price += productPrice - productDiscount;
      } else {
        price += productPrice;
      }

      origPrice += productOrigPrice;
    });

  return {
    price,
    origPrice,
  };
}

export const useGetBundleGroupsFromProducts = (
  bundleProducts?: BundleProducts
):
  | {
      bundleItemMap: BundleItemMap;
      bundleGroupMap: BundleGroupMap;
      bundleItemDisplayNameMap: BundleItemDisplayNameMap;
      subscriptionIntervals?: SubscriptionIntervalCollection;
    }
  | undefined => {
  const { search } = useLocation();
  return useMemo(() => {
    const searchParams = new URLSearchParams(search);
    const bundleItemDisplayNameMap: BundleItemDisplayNameMap = {};
    const bundleGroupMap =
      bundleProducts?.reduce((accumulator, current) => {
        const { groupName, product = {}, displayName } = current || {};
        if (groupName) {
          if (Array.isArray(accumulator[groupName])) {
            accumulator[groupName].push(current);
          } else {
            accumulator[groupName] = [current];
          }
        } else if (product) {
          const productName = product.name || 'product';
          accumulator[productName] = [current];
          bundleItemDisplayNameMap[productName] = displayName || productName;
        }
        return accumulator;
      }, {} as BundleGroupMap) || ({} as BundleGroupMap);

    // #region Create BundleItem Map
    const bundleItemMap: BundleItemMap = {};
    const subscriptionIntervalSet = new Set<string>();

    Object.keys(bundleGroupMap).forEach((key) => {
      const bundleGroup = bundleGroupMap[key];
      const bundleItemSkuKey = getBundleItemParamKey(key);
      const urlItemSku = searchParams.get(bundleItemSkuKey);

      let defaultVariant: BundleVariant | undefined;

      // If sku for this item is in url find it to populate initial bundleItemMap entry
      if (urlItemSku) {
        const foundItem = bundleGroup.find((bundleProduct, idx) => {
          const variantFromSku = bundleProduct?.product?.variants?.find(
            (variant) => {
              return variant?.sku === urlItemSku;
            }
          );
          if (variantFromSku) {
            defaultVariant = variantFromSku;
            return true;
          }
          return false;
        });

        if (foundItem?.groupName) {
          bundleItemDisplayNameMap[foundItem.groupName] =
            foundItem.displayName || '';
        }
      }

      if (bundleItemMap[key]) {
        bundleItemMap[key] = { selectedVariant: undefined } as any;
      }

      if (!defaultVariant) {
        defaultVariant = bundleGroup[0]?.product
          ?.variants?.[0] as BundleVariant;
      }

      bundleItemMap[key] =
        defaultVariant ||
        (bundleGroup[0]?.product?.variants?.[0] as BundleVariant);

      addSubscriptionIntervals(bundleGroup, subscriptionIntervalSet);
    });

    // #endregion

    return {
      bundleItemMap,
      bundleGroupMap,
      bundleItemDisplayNameMap,
      subscriptionIntervals: Array.from(subscriptionIntervalSet.values()).map(
        (intervalSetItem) => {
          const [interval, intervalCount, recurring] = intervalSetItem.split(
            '|'
          );
          return {
            interval,
            intervalCount: Number(intervalCount),
            recurring: Boolean(recurring),
          };
        }
      ),
    };
  }, [search]);
};

function addSubscriptionIntervals(
  bundleGroup: BundleProducts,
  subscriptionIntervalSet: Set<string>
) {
  bundleGroup.forEach((bundleGroupItem) => {
    const product = bundleGroupItem?.product;
    if (bundleGroupItem?.skus?.length && bundleGroupItem.skus.length > 1) {
      product?.variants?.forEach((variant) => {
        const { recurring, interval, intervalCount } = variant || {};
        if (!variant) {
          return;
        }

        if (recurring && interval && intervalCount) {
          subscriptionIntervalSet.add(
            `${interval}|${intervalCount}|${recurring}`
          );
        }
      });
    }
  });
}

export function getBundleItemParamKey(bundleItemGroupName: string) {
  return bundleItemGroupName + 'Sku';
}

export const allBundleItems = (
  allProducts: BundleProducts
): BundleProductItem[] => {
  return allProducts.reduce(
    (all: BundleProductItem[], product: BundleProducts[0]) => {
      if (!product) {
        return [...all];
      }

      const allVariants =
        product.product?.variants?.map(
          mapVariant(product.displayName || '', product.product)
        ) || [];

      return [...all, ...allVariants];
    },
    []
  );
};

export const selectedBundleItems = (
  selectedBundleItemMap: BundleItemMap,
  bundleGroupMap: BundleGroupMap,
  bundleItemDisplayNameMap: BundleItemDisplayNameMap
): BundleProductItem[] => {
  return Object.keys(selectedBundleItemMap)?.map(
    (itemKey): BundleProductItem => {
      const item = selectedBundleItemMap[itemKey];

      const product = bundleGroupMap[itemKey].find((product) => {
        return product?.product?.variants?.find((variant) => {
          return variant?.sku === item.sku;
        });
      })?.product;

      const description = product?.description;

      return {
        ...mapVariant(bundleItemDisplayNameMap[itemKey], product)(item),
        description,
      };
    }
  );
};

const mapVariant = (
  name: string,
  product?: Pick<SanityOpenfitProduct, 'stripeProductId'>
) => (variant: BundleVariant): BundleProductItem => {
  const nsfCertification = variant?.productCertifications?.find(
    ({ productCertification } = {}) =>
      productCertification && productCertification.name === 'NSF'
  );
  const nsfCertificationLink =
    (nsfCertification?.productCertification?.url &&
      nsfCertification?.id &&
      `${nsfCertification?.productCertification?.url}${nsfCertification?.id}`) ||
    '';

  const isFreeWithOrigPrice =
    !Boolean(variant?.price) && Boolean(variant?.origPrice);

  return {
    name: name,
    productId: product?.stripeProductId,
    sku: variant?.sku,
    image: getVariantImage(variant as ImageAwareObject),
    description: '',
    nsfCertification: !!nsfCertification,
    nsfCertificationLink,
    isFreeWithOrigPrice,
    price: variant?.price,
    origPrice: variant?.origPrice,
    ingredientsImage: variant?.variantIngredient
      ?.ingredientsImage as SanityImage,
  };
};

export const bundleMapToList = (
  bundleMap: BundleItemMap | BundleItemsPricingMap
) =>
  Object.keys(bundleMap)
    .map((key) => bundleMap[key])
    .filter(Boolean);
