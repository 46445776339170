import {
  getDefaultRequestCache,
  Immutable,
  openfitCachedFetch,
  getAcceptLanguage,
} from '@bbnb/openfit-frontend-shared';
import { SITE } from 'Constants/general';
import { getAuthorized } from 'helpers/Customer';
import { getTZ } from 'helpers/General';
import { Cart, UpdateCart } from './cart.api.model';

export function getCart(): Promise<Immutable<Cart>> {
  return openfitCachedFetch<Cart>(
    getDefaultRequestCache(),
    'network-only',
    `${process.env.GATSBY_REST_API_ENDPOINT}/me/cart`,
    {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        Authorization: getAuthorized(),
        TZ: getTZ(),
        'X-Site': SITE,
        'Accept-Language': getAcceptLanguage(),
      },
    }
  );
}

export function updateCart(
  updateCartObject: UpdateCart
): Promise<Immutable<Cart>> {
  const authToken = getAuthorized();
  const updateApi = authToken ? '/me/cart' : '/guest/cart';
  const body = JSON.stringify(
    authToken ? { store: true, ...updateCartObject } : updateCartObject
  );

  const headers: Record<string, string> = {
    'Content-Type': 'application/json',
    TZ: getTZ(),
    'X-Site': SITE,
    'Accept-Language': getAcceptLanguage(),
  };

  if (authToken) {
    headers.Authorization = authToken;
  }

  return openfitCachedFetch<Cart>(
    getDefaultRequestCache(),
    'network-only',
    `${process.env.GATSBY_REST_API_ENDPOINT}${updateApi}`,
    {
      method: 'POST',
      headers,
      body,
    }
  );
}
