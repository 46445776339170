import React from 'react';
import {
  Image,
  ImagePlaceholder,
  ImageWrapper,
  ImagePositionWrapper,
  SquareImageWrapper,
} from './ProductImage.styles';
import { GatsbyImageProps } from 'gatsby-plugin-image';
import { useIsBrowser } from 'utils/general';

export const ProductImage: React.FC<{
  image: GatsbyImageProps;
}> = ({ image }) => {
  const isBrowser = useIsBrowser();
  return (
    <SquareImageWrapper>
      <ImageWrapper>
        <ImagePositionWrapper>
          {isBrowser ? <Image {...image} /> : <ImagePlaceholder />}
        </ImagePositionWrapper>
      </ImageWrapper>
    </SquareImageWrapper>
  );
};
