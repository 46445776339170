import React, { FC, ComponentType } from 'react';
import styled, { css } from 'styled-components';
import { loadingDivStyles } from './LoadingPlaceholder.styles';

export function withLoading<T>(Component: ComponentType<T>) {
  return styled(Component)<{ loading?: boolean; borderRadius?: string }>`
    ${({ loading, borderRadius }) =>
      loading &&
      css`
        ${loadingDivStyles};
        border-radius: ${borderRadius ? `${borderRadius}` : '4px'};
      `}
  `;
}

export const LoadingWrapper = withLoading(styled.div``);

export const LoadingPlaceHolder: FC<{
  width?: string | number;
  height?: string | number;
  marginBottom?: string | number;
  count?: number;
}> = ({ width, height, marginBottom, count = 1 }) => {
  return (
    <>
      {Array.from({ length: count }).map((number, index) => (
        <LoadingWrapper
          key={index}
          style={{ width, height, marginBottom }}
          loading={true}
        />
      ))}
    </>
  );
};
