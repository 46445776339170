import styled, { css, keyframes } from 'styled-components';

export const PlaceholderContainer = styled.div<{ height?: string }>`
  display: flex;
  justify-content: center;
  align-items: center;
  height: ${({ height }) => height || 'auto'};
`;

const pulse = keyframes`
  0% {
    opacity: 0.4;
  }
  25% {
    opacity: 0.3;
  }
  50% {
    opacity: 0.4;
  }
  75% {
    opacity: 0.5;
  }
  100% {
    opacity: 0.4;
  }
`;

const pulseStyles = css`
  animation: ${pulse} 1.5s infinite;
  background: ${({ theme }) => theme.colors.basic400};
`;

export const loadingDivStyles = css`
  ${pulseStyles}
  & > * {
    visibility: hidden;
  }
`;
