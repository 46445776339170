import React from 'react';
import { ProductTileProps } from './ProductTile.model';
import { ProductTileGridStyled } from './ProductTileGrid.styles';

export const ProductTileGrid: React.FC<{
  children:
    | React.ReactElement<ProductTileProps>[]
    | React.ReactElement<ProductTileProps>;
}> = ({ children }) => (
  <ProductTileGridStyled>{children}</ProductTileGridStyled>
);
